<template>
    <v-container>
        <v-row>
            <v-col md="3">
                <v-row>
                    <create-meal></create-meal>
                <ExportExcel class="py-4" end-point="/meal/export" :params="filters" v-if="returnAbility('meal:export')"/>
                </v-row>
            </v-col>
            <v-col md="3">
                <v-autocomplete :dark="$store.state.isDarkMode" v-model="filters.id" :items="mealItems" item-text="name" item-value="id"
                    :search-input.sync="mealSearch" :loading="meal.getMealsNameState.loading"
                    :label="$t('meal name')">
                </v-autocomplete>
            </v-col>
            <v-col md="2">
                <v-select :dark="$store.state.isDarkMode" v-model="filters.status" :label="$t('status')" :items="stateMeal" item-text="name" item-value="id">
                </v-select>
            </v-col>
            <v-col md="2">
                <v-text-field :dark="$store.state.isDarkMode" v-model="filters.price" :label="$t('price')" type="number">
                </v-text-field>
            </v-col>
            <v-col md="1" class="py-7">
                <v-icon :dark="$store.state.isDarkMode" large color="gray" @click="() => {
                    filters = {}
                    fetchData();
                } ">mdi-close</v-icon>
            </v-col>
            <v-col md="1" class="py-7">
                <v-icon :dark="$store.state.isDarkMode" large color="gray" @click="setData"> mdi-magnify </v-icon>
            </v-col>

        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="0" class="pt-5">
                    <base-table2 @re-fetch-data="fetchData" @re-fetch-paginated-data="fetchData($event)"
                        :tableOptions="tableOptions" :enableDelete="true">
                    </base-table2>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import baseTable2 from '../../../components/base/basetable2.vue'
import { meal } from '../../../store/auth'
import createMeal from './creatMeal.vue'
import ExportExcel from "../components/core/ExportExcel.vue";

// import editCostCenter from "./editCostCenter.vue";
export default {
    components: {
        baseTable2,
        createMeal,
        ExportExcel,
    },
    data() {
        return {
            meal,
            dialog: false,
            submitBtnLoading: false,
            mealSearch: null,
            stateMeal:[
                {
                    name: this.$t('available'),
                    id:  'available',
                },
                {
                    name: this.$t('unavailable'),
                    id:  'unavailable',
                }
            ],
            name: "",
            filters:{
                id: null,
                price:  null,
                status: null,
            },
        };
    },
    methods: {
        fetchData(page) {
            let payload = {
                ...this.filters,
                page: page,
            }
            meal.geatAllMeal(payload)
        },
        setData(){
            this.fetchData();
        },
    },
    computed: {
        tableOptions() {
            return meal.mealsTable
        },
         mealItems() {
            return meal.mealData;
        },
    },
    watch: {
        mealSearch(val) {
            if ((val != "") & (val != null)) {
                meal.getMealsName(val);
            } else {
                meal.mealData = [];
            }
        },
    },
    created() {
        meal.geatAllMeal()
    }
};
</script>