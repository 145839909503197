<template>
    <v-row>
        <v-col>
            <template>
                <v-row justify="center">
                    <v-dialog v-model="dialog" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn style="margin-top: 30px;" color="green" dark v-bind="attrs" v-on="on" @click="clearData">
                                <v-icon size="20">mdi-plus</v-icon>
                                {{ $t('add meal') }}
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="justify-center" >
                                <span class="text-h3">{{ $t('add meal') }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                            <app-image-input-file :dark="$store.state.isDarkMode"   :resetImage="resetImageInput" :createFeature="true"
                                                :imgHeight="140" :imgWidth="125" :placeHolder="$t('image')"
                                                @fileValue="setMealPhoto($event)"
                                                @fileRemoved="deleteMealPhoto"></app-image-input-file>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field :dark="$store.state.isDarkMode"  v-model="data.name" :label="$t('name')" :hint="$t('requird')"
                                                required></v-text-field>
                                            <v-text-field class="py-5"  :dark="$store.state.isDarkMode" dense v-model="data.barcode"
                                               :label="$t('parcode')"  ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-autocomplete :dark="$store.state.isDarkMode"  v-model="data.status" :items="myitems" item-text="name"
                                                :hint="$t('requird')" item-value="value"
                                                :label="$t('status')"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field :dark="$store.state.isDarkMode"  v-model="data.price" :label="$t('price')" type="number"
                                                :hint="$t('requird')" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-autocomplete :dark="$store.state.isDarkMode"  :label="$t('Group')" v-model="group_id" color="#757575"
                                                item-text="name" item-value="id" :items="group_idA"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6" class="py-4" >
                                            <AutoCompleteField :label="$t('invoice type')" v-model="data.invoice_template_id"
                                              endPoint="/inventories/invoice-template/auto-complete" />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-select :dark="$store.state.isDarkMode"  v-model="data.days" chips :items="days" item-text="name"
                                                :hint="$t('requird')" item-value="value"
                                                :menu-props="{ maxHeight: '400' }" :label="$t('dayes Avilable in')"
                                                multiple></v-select>
                                        </v-col>
                                       
                                        <v-col cols="12">
                                            <v-text-field :dark="$store.state.isDarkMode"  v-model="data.description" :label="$t('description')"
                                                :hint="$t('requird')" required></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="dialog = false">
                                    {{ $t('cancel') }}
                                </v-btn>
                                <v-btn :color="btnColor" text @click="storeMeal">
                                    <span v-if="!meal.storeMealState.loading"> {{ $t('storage') }}</span>
                                    <v-progress-circular v-else :width="3" color="blue"
                                        indeterminate></v-progress-circular>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
        </v-col>
    </v-row>
</template>
<script>
import imageInputFile from "../../../components/base/imageInputFile.vue";
import { meal } from '../../../store/auth';
import notify from '../../../helpers/toast-notifications';
import axios from "axios";
import AutoCompleteField from "../components/core/AutoCompleteField.vue";

const mealState = () => {
    return {
        name: '',
        description: '',
        image: null,
        status: '',
        price: null,
        barcode: null,
        invoice_template_id: null,
        days: undefined || [],
        group_id: null,
    }
}
export default {
    components: {
        appImageInputFile: imageInputFile,
        AutoCompleteField,
    },
    data() {
        return {
            meal,
            group_id: null,
            group_idA: [],
            group_idLoading: false,
            data: mealState(),
            resetImageInput: false,
            imagePath: '',
            dialog: false,
            items: [
                {
                    name: 'available',
                    value: 'available'
                },
                {
                    name: 'unavailable',
                    value: 'unavailable'
                }
            ],
            items: [
                {
                    name: 'available',
                    value: 'available'
                },
                {
                    name: 'unavailable',
                    value: 'unavailable'
                }
            ],
            items2: [
                {
                    name: 'متوفر',
                    value: 'available'
                },
                {
                    name: ' غير متوفر',
                    value: 'unavailable'
                }
            ],
            enDays: [
                {
                    name: 'sunday',
                    value: 'sunday'
                },
                {
                    name: 'monday',
                    value: 'monday',
                },
                {
                    name: 'tuesday',
                    value: 'tuesday',


                },
                {
                    name: 'wednesday',
                    value: 'wednesday',


                },
                {
                    name: 'thursday',
                    value: 'thursday',

                },
                {
                    name: 'friday',
                    value: 'friday',

                },
                {
                    name: 'saturday',
                    value: 'saturday'


                }
            ],
            arDays: [
                {
                    name: 'الأحد',
                    value: 'sunday'
                },
                {
                    name: 'الإثنين',
                    value: 'monday',
                },
                {
                    name: 'الثلاثاء',
                    value: 'tuesday',


                },
                {
                    name: 'الأربعاء',
                    value: 'wednesday',


                },
                {
                    name: 'الخميس',
                    value: 'thursday',

                },
                {
                    name: 'الجمعة',
                    value: 'friday',

                },
                {
                    name: 'السبت',
                    value: 'saturday'


                }
            ]

        }
    },
    methods: {
        clearData(){
            this.data.barcode = null;
            this.data.days = [];
            this.data.description = null;
            this.data.image = null;
            this.data.invoice_template_id = null;
            this.data.name = null;
            this.data.price = null;
            this.data.status = null;
            this.data.group_id = null;
        },
        async groupAc() {
            try {
                const searchResult = await axios.get(
                    "/meal-group"
                );
                this.group_idA = searchResult.data.data;
            } catch (err) {
                console.log("err", err);
            }
        },
        setMealPhoto(file) {
            this.data.image = file;
        },
        lengthArr(arr) {
            if (Array.isArray(arr) && arr.length)
                return false;
            else
                return true;
        },
        deleteMealPhoto() {
            this.data.image = null;
        },
        async storeMeal() {
            this.data = {
                ...this.data,
                group_id: this.group_id,
            };
            if (this.btnColor == 'blue') {
                try {
                    await meal.StoreMeal(this.data);
                    this.data = {}
                this.deleteMealPhoto()
                this.resetImageInput = true
                this.dialog = false
                } catch {

                }
                
            } else {
                notify(
                    this.$t('please complete all info'),
                    { timeout: 4000, rtl: true },
                    "error"
                );
            }

        }
    },
    computed: {
        btnColor() {
            if (this.data.image == null | this.data.description == '' | this.data.name == '' | this.data.price == null |
             this.lengthArr(this.data.days) | this.data.barcode == null | this.data.invoice_template_id == null) {
                return 'gray'
            } else {
                return 'blue'
            }
        },
        myitems() {
            if (this.$i18n.locale == 'en') {
                return this.items
            } else {
                return this.items2
            }
        },
        days() {
            if (this.$i18n.locale == 'en') {
                return this.enDays
            } else {
                return this.arDays
            }
        }

    },
    created() {
        this.groupAc();
    },

}


</script>
